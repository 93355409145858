var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "9" } },
                [
                  _c(
                    "CCard",
                    [
                      _c("CCardBody", [
                        _c("div", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "pages.host.HostTemplateTextIndex.info",
                              expression:
                                "'pages.host.HostTemplateTextIndex.info'"
                            }
                          ]
                        })
                      ])
                    ],
                    1
                  ),
                  _c("DataTable", {
                    attrs: {
                      tableFilter: false,
                      items: _vm.templateTexts,
                      fields: [
                        {
                          key: "templateTextType",
                          label: _vm.$t("tables.templateTexts.templateTextType")
                        },
                        {
                          key: "updatedAt",
                          label: _vm.$t("tables.templateTexts.updatedAt")
                        },
                        {
                          key: "actions",
                          label: "",
                          _style: "width:30%",
                          sorter: false,
                          filter: false
                        }
                      ],
                      "table-filter-value": _vm.tableFilterValue,
                      "sorter-value": _vm.sorterValue,
                      loading: _vm.loading
                    },
                    on: {
                      "update:tableFilterValue": function($event) {
                        _vm.tableFilterValue = $event
                      },
                      "update:table-filter-value": function($event) {
                        _vm.tableFilterValue = $event
                      },
                      "update:sorterValue": function($event) {
                        _vm.sorterValue = $event
                      },
                      "update:sorter-value": function($event) {
                        _vm.sorterValue = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "templateTextType",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "enums.templateTextTypes." +
                                          item.templateTextType
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "updatedAt",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  _c("FormatedTimestamp", {
                                    attrs: { date: item.updatedAt }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "actions",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        to: {
                                          name: "HostTemplateTextEdit",
                                          params: { templateTextId: item.id }
                                        }
                                      }
                                    },
                                    [_vm._v(" Editieren ")]
                                  ),
                                  _c(
                                    "CButton",
                                    {
                                      attrs: { color: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onDelete(item.id)
                                        }
                                      }
                                    },
                                    [_vm._v("Löschen")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2372856903
                    )
                  }),
                  _c("CPagination", {
                    attrs: { pages: _vm.pages, activePage: _vm.currentPage },
                    on: {
                      "update:activePage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:active-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: 3 } },
                [
                  _c(
                    "CreateCard",
                    {
                      attrs: {
                        loading: _vm.loading,
                        errorMessage: _vm.message,
                        title: _vm.$t(
                          "pages.host.HostTemplateTextIndex.createTitle"
                        ),
                        buttonEnabled: !!_vm.templateText.templateTextType
                      },
                      on: { handleSubmit: _vm.handleSubmit }
                    },
                    [
                      _c("ValidatedSelect", {
                        attrs: {
                          translationKey: "templateText.templateTextType",
                          field: _vm.$v.templateText.templateTextType,
                          options: _vm.availableTemplateTextTypeOptions,
                          value: _vm.$v.templateText.templateTextType.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.templateText.templateTextType,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }